import React from 'react';
import { PRICING_PLANS } from '../constants';
import HeaderNavigation from '../components/HeaderNavigation';
import PricingPlan from '../components/PricingPlan';
import Footer from '../components/Footer';

const PricingPage = () => {
    return (
        <>
            <header className={'pricing-page-header'}>
                <div className="container">
                    <HeaderNavigation />
                    <h1>Straight Forward Pricing</h1>
                    <div className={'pricing-page-description'}>
                        Start your free trial today - no credit card required.
                    </div>
                </div>
            </header>
            <main>
                <div className={'pricing-page-plans-container'}>
                    <div className={'row pricing-page-plan-row'}>
                        <div className={'col-12 col-lg-3 pricing-page-plan-col'}>
                            <PricingPlan plan={PRICING_PLANS.trial}/>
                        </div>
                        <div className={'col-12 col-lg-3 pricing-page-plan-col'}>
                            <PricingPlan plan={PRICING_PLANS.evaluation}/>
                        </div>
                        <div className={'col-12 col-lg-3 pricing-page-plan-col'}>
                            <PricingPlan plan={PRICING_PLANS.engagement}/>
                        </div>
                        <div className={'col-12 col-lg-3 pricing-page-plan-col'}>
                            <PricingPlan plan={PRICING_PLANS.development}/>
                        </div>
                    </div>
                </div>
            </main>
            <Footer/>
        </>
    );
};

export default PricingPage;
