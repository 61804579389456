import React from 'react';
import GetStarted from './GetStarted';
import { getPricingPlanInfo } from '../utils';

const PricingPlan = ({ plan }) => {
    const planInfo = getPricingPlanInfo(plan);
    return (
        <div className={planInfo.mostPopular ? 'pricing-plan-most-popular' : 'pricing-plan'}>
            <div>
                <h3 className={'pricing-plan-name'}>
                    {planInfo.name}
                </h3>
            </div>
            <div className={'pricing-plan-description'}>
                {planInfo.description}
            </div>
            <div className={'pricing-plan-cost'}>
                {planInfo.cost}
            </div>
            <div className={'pricing-plan-cost-description'}>
                {planInfo.costDescription1}<br/>
                {planInfo.costDescription2}
            </div>
            <div className={'pricing-plan-button'}>
                <GetStarted text={planInfo.buttonText} primary={planInfo.mostPopular}/>
            </div>
            <div>
                <h3 className={'pricing-plan-perk-description'}>
                    {planInfo.perksDescription}
                </h3>
                <ul>
                    {
                        planInfo.perks.map(perk => (
                            <li className='pricing-plan-perk'>
                                {perk}
                            </li>
                        ))
                    }
                    {
                        planInfo.disadvantages.map(disadvantage => (
                            <li className='pricing-plan-disadvantage'>
                                {disadvantage}
                            </li>
                        ))
                    }
                </ul>
            </div>
        </div>
    )
};

export default PricingPlan;
