import React from 'react';
import icoFacebook from '../images/ico-facebook.svg';
import icoTwitter from '../images/ico-twitter.svg';
import icoInstagram from '../images/ico-instagram.svg';

const Footer = () => (
  <footer>
    <div className="container">
      <div className="row">
        <div className="credits col-md-6">
          © {new Date().getFullYear()} All Rights Reserved. DeepDive360 Inc.
        </div>

        <div className="social-media col-md-6 text-md-right">
          <a href="https://www.facebook.com/DeepDive360-103968187953041">
            <img alt="Facebook" src={icoFacebook} />
          </a>
          <a href="https://twitter.com/deep_dive360">
            <img alt="Twitter" src={icoTwitter} />
          </a>

          <a href="https://www.instagram.com/deepdive360">
            <img alt="Instagram" src={icoInstagram} />
          </a>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
