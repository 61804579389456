import { PRICING_PLANS } from './constants';

export const getPricingPlanInfo = plan => {
    switch (plan) {
        case PRICING_PLANS.development:
            return {
                name: 'Development',
                description: 'Enterprise-grade features for large organizations.',
                cost: '$15',
                costDescription1: 'Per employee / billed monthly',
                costDescription2: 'Sold in blocks of 5',
                buttonText: 'Start Trial',
                perksDescription: 'Everything in Engagement, plus:',
                perks: [
                    'Employee Development Resource Library',
                    'White-Glove Setup & Support',
                    'Demographic Reporting Engine (Optional)',
                    'White Labeling (Optional)',
                ],
                disadvantages: [],
                mostPopular: false,
            };
        case PRICING_PLANS.engagement:
            return {
                name: 'Engagement',
                description: 'Enhance your evaluation process & get premium support.',
                cost: '$10',
                costDescription1: 'Per employee / billed monthly',
                costDescription2: 'Sold in blocks of 5',
                buttonText: 'Start Trial',
                perksDescription: 'Everything in Evaluation, plus:',
                perks: [
                    'Employee Self-Assessments',
                    'Customized Reporting',
                    'Account Representative',
                ],
                disadvantages: [],
                mostPopular: true,
            }
        case PRICING_PLANS.evaluation:
            return {
                name: 'Evaluation',
                description: 'Everything you need to run stress-free employee evaluations.',
                cost: '$5',
                costDescription1: 'Per employee / billed monthly',
                costDescription2: 'Sold in blocks of 5',
                buttonText: 'Start Trial',
                perksDescription: 'Digitize your evaluation process:',
                perks: [
                    'Unlimited Organizations',
                    'Unlimited Evaluations',
                    'Unlimited Evaluators',
                    'Evaluation Template Builder',
                    'Web & Mobile Scoring',
                    'Excel Data Export',
                    'Employee Evaluation Reports',
                    'Employee Development Tracking',
                    'Compare Employees',
                    'Email & Phone Support',
                ],
                disadvantages: [],
                mostPopular: false,
            }
        case PRICING_PLANS.trial:
        default:
            return {
                name: 'Trial',
                description: 'Great way to see the benefits of digitizing your evaluation process.',
                cost: '$0',
                costDescription1: 'Free Unlimited',
                costDescription2: 'No credit card required',
                buttonText: 'Sign Up',
                perksDescription: 'Everything in Evaluation, except:',
                perks: [
                    'Limit of 5 Employees',
                    '1 Group Limit',
                    '1 Evaluation Limit',
                    '2 Evaluators Limit',
                ],
                disadvantages: [
                    'Employee Development Tracking',
                    'Data Export to Excel',
                ],
                mostPopular: false,
            }
    }
};
